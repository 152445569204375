import React, { useEffect, useRef, useState } from 'react';
import './App.css';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyAEMk5K-wPJQ6xtm92j9IYRO2KTWPzBdFM",
  authDomain: "oncosupport-group-4.firebaseapp.com",
  projectId: "oncosupport-group-4",
  storageBucket: "oncosupport-group-4.appspot.com",
  messagingSenderId: "392004711719",
  appId: "1:392004711719:web:9a0b0ba1276a6c82786502",
  measurementId: "G-3TB0MYWK9G"})

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();


function App() {

  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header>
        <h1>OncoSupport Group Chat</h1>
        <SignOut />
      </header>

      <section>
        {user ? <ChatRoom /> : <SignIn />}
      </section>

    </div>
  );
}

function SignIn() {
  console.log(window.sessionStorage);
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider).catch((error) => {
      console.error("Sign in error:", error);
    });
  }

  return (
    <>
      <div className="center-text">
        <p>Welcome to your custom support group chat!</p>
        <p>Use this application to communicate with your entire support group, schedule meetings via the Google Meet link, meet up with each other, or simply provide support.</p>
      </div>
      <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button>
    
    </>
  )

}

function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Sign Out</button>
  )
}


function ChatRoom() {
  const dummy = useRef();
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limit(25);

  const [messages] = useCollectionData(query, { idField: 'id' });
  const [formValue, setFormValue] = useState('');

  useEffect(() => {
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]); // <-- Important part

  const sendMessage = async (e) => {
    e.preventDefault();
  
    const { uid, photoURL, displayName } = auth.currentUser;
  
    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL,
      displayName // Include displayName here
    });
  
    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  };
  

  return (
    <>
      <main>
        {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
        <span ref={dummy}></span>
      </main>
      <form onSubmit={sendMessage}>
        <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="say something nice" />
        <button type="submit">⬆︎</button>
      </form>
    </>
  );
}



function ChatMessage(props) {
  const { text, uid, photoURL, displayName } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (
    <div className={`message ${messageClass}`}>
      <div className="message-body">
        <img src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} />
        <div className="message-content">
          <div className="message-header">{displayName}</div>
          <p>{text}</p>
        </div>
      </div>
    </div>
  )
  
}




export default App;
